import React, { FC } from 'react';
import { Link } from 'gatsby';

import BannerImage from 'components/Banner/BannerImage';

import { CouponSectionProps } from './models';

import './CouponSection.scss';

const CouponSection: FC<CouponSectionProps> = ({ image, alt, caption, fileToDownload }) => (
  <div className="coupon-section" data-testid="coupon-section">
    <Link to={fileToDownload.fallbackUrl} className="coupon-section__link">
      <BannerImage image={image} alt={alt} />
    </Link>
    <p className="coupon-section__caption">{caption}</p>
  </div>
);

export default CouponSection;
